import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {connect} from "./redux/blockchain/blockchainActions";
import {fetchData} from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/258.png";
import i2 from "./assets/images/56.png";
import i3 from "./assets/images/65.png";

export const StyledSelect = styled.select`
  padding: 10px 40px;
  margin-right: 10px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  font-weight: bold;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  color: #333;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
`;

export const StyledOption = styled.option`
 font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
`;

export const StyledButton = styled.button`
  padding: 10px 40px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  font-weight: bold;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  color: #333;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    position: relative;
    top: 3px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    position: relative;
    top: 3px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 1201px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  width: 300px;
  height: 300px;
  @media (min-width: 769px) {
    width: 400px;
    height: 400px;
  }
  @media (min-width: 1025px) {
    width: 500px;
    height: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [feedback, setFeedback] = useState("Nothing quite like minting a good crap NFT!");
    const [claimingNft, setClaimingNft] = useState(false);
    const priceArray = [0.05, 2, 15, 35, 100];

    let price = priceArray[0];
    switch (true) {
        case (data.totalSupply > 9000):
            price = priceArray[4];
            break;
        case (data.totalSupply > 7000):
            price = priceArray[3];
            break;
        case (data.totalSupply > 5000):
            price = priceArray[2];
            break;
        case (data.totalSupply > 3000):
            price = priceArray[1];
            break;
        default:
            price = priceArray[0];
    }

    let claimNumber = 1;
    const claimNFTs = (_amount) => {
        if (_amount <= 0) {
            return;
        }
        setFeedback("Pushing out (minting) your dumpy little turd...");
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mint(blockchain.account, _amount)
            .send({
                gasLimit: "285000",
                to: "0x984B9CF2f2343ec6f065fAeED5Bee6bdeEA1e631",
                from: blockchain.account,
                value: blockchain.web3.utils.toWei((price * _amount).toString(), "ether"),
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback(`Sorry, feeling a little constipated. Try again after some coffee. (TRY SWITCHING YOUR ESTIMATED FART (GAS) FEE TO MEDIUM.)`);
                setClaimingNft(false);
            })
            .then((receipt) => {
                setFeedback(
                    "WOW, you now own a fresh new Treasure Turd. Go visit Opensea.io to view it."
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const numberNFTs = (v) => {
        claimNumber = v;
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    return (
        <s.Screen style={{backgroundColor: "#4755d7"}}>
            <s.Container>
                <s.Nav>
                    <s.TextDescription style={{textAlign: "center"}}>
                        <s.UnorderedList style={{textAlign: "center"}}>
                            <s.NavItem>
                                <s.Anchor href={"#home"}>
                                    Home
                                </s.Anchor>
                            </s.NavItem>
                            <s.NavItem>
                                <s.Anchor href={"#price"}>
                                    Price Pyramid
                                </s.Anchor>
                            </s.NavItem>
                            <s.NavItem>
                                <s.Anchor href={"#road"}>
                                    Road Map
                                </s.Anchor>
                            </s.NavItem>
                        </s.UnorderedList>
                    </s.TextDescription>
                </s.Nav>
            </s.Container>
            <s.Container flex={1} ai={"center"} style={{padding: 24}} id={"home"}>
                <s.TextTitle style={{textAlign: "center", fontSize: 58}}>
                    Mint a Treasure Turd!
                </s.TextTitle>
                <s.TextSubTitle style={{textAlign: "center"}}>
                    Join Discord
                </s.TextSubTitle>
                <s.Anchor href={"https://discord.gg/5xwQ3hMX"} target={"_blank"}>
                    <StyledButton>
                        DISCORD
                    </StyledButton>
                </s.Anchor>
                <s.SpacerMedium/>
                <ResponsiveWrapper flex={1} style={{padding: 24}}>
                    <s.Container flex={1} jc={"center"} ai={"center"}>
                        <StyledImg alt={"example"} src={i1}/>
                        <s.TextTitle style={{textAlign: "center", fontSize: 65, fontWeight: "bold"}}>
                            {data.totalSupply}/10000
                        </s.TextTitle>
                    </s.Container>
                    <s.SpacerMedium/>
                    <s.Container
                        flex={1}
                        jc={"center"}
                        ai={"center"}
                        style={{backgroundColor: "#383838", padding: 24}}
                    >
                        {Number(data.totalSupply) == 10000 ? (
                            <>
                                <s.TextTitle style={{textAlign: "center"}}>
                                    The sale has ended.
                                </s.TextTitle>
                                <s.SpacerSmall/>
                                <s.TextDescription style={{textAlign: "center"}}>
                                    You can still find Treasure Turds on{" "}
                                    <a target={"_blank"} href={"https://opensea.io/collection/treasureturds"}>
                                        Opensea.io
                                    </a>
                                </s.TextDescription>
                            </>
                        ) : (
                            <>
                                {blockchain.account === "" ||
                                blockchain.smartContract === null ? (
                                    <s.TextTitle style={{textAlign: "center"}}>
                                        Connect to see Treasure Turd price.
                                    </s.TextTitle>
                                ) : (
                                    <s.TextTitle style={{textAlign: "center"}}>
                                        1 Treasure Turd costs {price} MATIC.
                                    </s.TextTitle>
                                )}
                                <s.SpacerXSmall/>
                                <s.TextDescription style={{textAlign: "center"}}>
                                    Excluding gas fee.
                                </s.TextDescription>
                                <s.SpacerSmall/>
                                <s.TextDescription style={{textAlign: "center"}}>
                                    {feedback}
                                </s.TextDescription>
                                <s.SpacerMedium/>
                                {blockchain.account === "" ||
                                blockchain.smartContract === null ? (
                                    <s.Container ai={"center"} jc={"center"}>
                                        <s.TextDescription style={{textAlign: "center"}}>
                                            Connect to the Polygon network
                                        </s.TextDescription>
                                        <s.SpacerFigginHuge/>
                                        <StyledButton onClick={(e) => { e.preventDefault(); dispatch(connect()); getData(); }} >
                                            CONNECT
                                        </StyledButton>
                                        {blockchain.errorMsg !== "" ? (
                                            <>
                                                <s.SpacerSmall/>
                                                <s.TextDescription style={{textAlign: "center"}}>
                                                    {blockchain.errorMsg}
                                                </s.TextDescription>
                                            </>
                                        ) : null}
                                    </s.Container>
                                ) : (
                                    <s.Container ai={"center"} jc={"center"}>
                                        <StyledSelect disabled={claimingNft ? 1 : 0} onChange={e => { numberNFTs(e.target.value); }} style={{width: "75%"}}>
                                            <StyledOption value={1}>1</StyledOption>
                                            <StyledOption value={2}>2</StyledOption>
                                            <StyledOption value={3}>3</StyledOption>
                                            <StyledOption value={4}>4</StyledOption>
                                            <StyledOption value={5}>5</StyledOption>
                                            <StyledOption value={6}>6</StyledOption>
                                            <StyledOption value={7}>7</StyledOption>
                                            <StyledOption value={8}>8</StyledOption>
                                            <StyledOption value={9}>9</StyledOption>
                                            <StyledOption value={10}>10</StyledOption>
                                        </StyledSelect>
                                        <s.SpacerSmall/>
                                        <StyledButton style={{width: "75%"}}
                                            disabled={claimingNft ? 1 : 0}
                                            onClick={(e) => { e.preventDefault(); claimNFTs(claimNumber); getData(); }} >
                                            {claimingNft ? "BUSY" : "MINT Treasure Turds (" + price + " Matic Each)"}
                                        </StyledButton>
                                    </s.Container>
                                )}
                            </>
                        )}
                        <s.SpacerLarge/>
                        <s.Anchor href={"https://polygonscan.com/token/0x4bb6782e260ffaa7ec7ee25de89ee1daac0b6c54"} target={"_blank"}>
                            <StyledButton>
                                POLYGONSCAN
                            </StyledButton>
                        </s.Anchor>
                        <s.SpacerFigginHuge/>
                        <s.TextDescription style={{textAlign: "center", fontSize: 24}}>
                            Please make sure you are connected to the right network (Polygon
                            Mainnet) and the correct address. Please note: Once you make the
                            purchase, you cannot undo this action.
                        </s.TextDescription>
                        <s.SpacerSmall/>
                        <s.TextDescription style={{textAlign: "center", fontSize: 24}}>
                            We have set the gas limit to 285000 for the contract to successfully
                            mint your NFT. We recommend that you don't change the gas limit.
                        </s.TextDescription>
                    </s.Container>
                </ResponsiveWrapper>
                <s.SpacerFigginHuge/>
            </s.Container>
            <s.Container style={{background: "#d79d47"}} id={"price"}>
                <s.SpacerFigginHuge/>
                <ResponsiveWrapper>
                    <s.Container flex={1} jc={"center"} ai={"center"}>
                        <s.TextTitle style={{textAlign: "center"}}>
                            Heaping Turd Price Pyramid
                        </s.TextTitle>
                        <s.SpacerMedium/>
                        <s.TextDescription>
                            <s.UnorderedList style={{textAlign: "center"}}>
                                <s.ListItem>
                                    {priceArray[0]} MATIC until 3,000 Turds sold
                                </s.ListItem>
                                <s.ListItem>
                                    {priceArray[1]} MATIC until 5,000 Turds sold
                                </s.ListItem>
                                <s.ListItem>
                                    {priceArray[2]} MATIC until 7,000 Turds sold
                                </s.ListItem>
                                <s.ListItem>
                                    {priceArray[3]} MATIC until 9,000 Turds sold
                                </s.ListItem>
                                <s.ListItem>
                                    {priceArray[4]} MATIC until the final 10,000 Turds have been sold.
                                </s.ListItem>
                            </s.UnorderedList>
                        </s.TextDescription>
                        <s.SpacerMedium/>
                    </s.Container>
                    <s.Container flex={1} jc={"center"} ai={"center"}>
                        <StyledImg alt={"example"} src={i2}/>
                    </s.Container>
                </ResponsiveWrapper>
                <s.SpacerFigginHuge/>
            </s.Container>
            <s.Container style={{background: "#a947d7"}} id={"road"}>
                <s.SpacerFigginHuge/>
                <ResponsiveWrapper>
                    <s.Container flex={1} jc={"center"} ai={"center"}>
                        <StyledImg alt={"example"} src={i3}/>
                    </s.Container>
                    <s.Container flex={1} jc={"center"} ai={"center"}>
                        <s.SpacerMedium/>
                        <s.TextTitle style={{textAlign: "center"}}>
                            Turds Path Down the Drain
                        </s.TextTitle>
                        <s.SpacerMedium/>
                        <s.TextDescription>
                            <s.UnorderedList style={{textAlign: "center"}}>
                                <s.ListItem>
                                    Phase 1: Launch Turds
                                </s.ListItem>
                                <s.ListItem>
                                    Phase 2: Sell Some Turds
                                </s.ListItem>
                                <s.ListItem>
                                    Phase 3: Consistent quarterly donations to gut health research with NFT receipts as
                                    proof.
                                </s.ListItem>
                                <s.ListItem>
                                    Phase 4: Sell out of Turds
                                </s.ListItem>
                                <s.ListItem>
                                    Phase 5: Wipe
                                </s.ListItem>
                            </s.UnorderedList>
                        </s.TextDescription>
                        <s.SpacerMedium/>
                    </s.Container>
                </ResponsiveWrapper>
                <s.SpacerFigginHuge/>
            </s.Container>
            <s.Container>
                <s.Footer>
                    <s.TextDescription>
                        &#169; 2022 Treasure Turds
                    </s.TextDescription>
                </s.Footer>
            </s.Container>
        </s.Screen>
    );
}

export default App;
