import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--dark-grey);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing space between components
export const SpacerFigginHuge = styled.div`
  height: 62px;
  width: 62px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--white);
  font-size: 46px;
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: var(--white);
  font-size: 36px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--white);
  font-size: 28px;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const UnorderedList = styled.ul`
    list-style: none;
    padding: 0px;
    margin: 0px;
`;

export const ListItem = styled.li`
    display: block;
`;

export const NavItem = styled.li`
    display: inline;
    padding: 0px 25px;
`;

export const Anchor = styled.a`
    color: white;
    text-decoration: none;
`;

export const Footer = styled.footer`
    background-color: #333;
    width: 100%;
    text-align: center;
    padding: 10px 0px;
`;

export const Nav = styled.nav`
    background-color: #333;
    width: 100%;
    text-align: center;
    padding: 10px 0px;
`;
